'use client';

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from './Header.module.css';
import { Menu } from 'lucide-react';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    // <header className="bg-gray-900 text-white">
    <header className={`${styles.header} bg-white`}>
      <div className={`${styles.container} container mx-auto px-4 py-4 flex justify-between items-center`}>
        <div className="flex items-center">
          <Link href="/">
            <Image className={`${styles.image}`} src="/images/logo-dark.svg" alt="Sloane InfoTech" width={150} height={64} />
          </Link>
        </div>

        {/* Desktop Menu */}
        <nav className=" md:flex space-x-6">
          <Link href="/" className="hover:text-gray-300">Home</Link>
          <Link href="/services" className="hover:text-gray-300">Services</Link>
          <Link href="/portfolio" className="hover:text-gray-300">Portfolio</Link>
          <Link href="/about" className="hover:text-gray-300">About</Link>
          <Link href="/contact" className="hover:text-gray-300">Contact</Link>
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav className="md:hidden bg-gray-800 px-4 py-2">
          <Link href="/" className="block py-2 hover:text-gray-300">Home</Link>
          <Link href="/services" className="block py-2 hover:text-gray-300">Services</Link>
          <Link href="/portfolio" className="block py-2 hover:text-gray-300">Portfolio</Link>
          <Link href="/about" className="block py-2 hover:text-gray-300">About</Link>
          <Link href="/contact" className="block py-2 hover:text-gray-300">Contact</Link>
        </nav>
      )}
    </header>
  );
};


export const Header2: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    return (
  
      <header className="container mx-auto py-6 px-4">
          <nav className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">Sloane</h1>
            <ul className="hidden md:flex space-x-6">
              <li><a href="#expertise" className="hover:text-blue-400 transition">Expertise</a></li>
              <li><a href="#projects" className="hover:text-blue-400 transition">Projects</a></li>
              <li><a href="#process" className="hover:text-blue-400 transition">Process</a></li>
              <li><a href="#contact" className="hover:text-blue-400 transition">Contact</a></li>
            </ul>
          </nav>
        </header>
  
    );
  };


  export const Header3 = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 750);
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    return (
      <header className="bg-white shadow-md">
        <div className="container max-w-8xl mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link href="/">
                <img className="h-14" src="/images/logo.svg" alt="Sloane InfoTech"  />
              {/* <Image className={`${styles.image}`} src="/images/logo.svg" alt="Sloane InfoTech" width={150} height={86} /> */}
              </Link>
            </div>
{/*   
            {!isMobile && (
              <nav className="hidden md:flex space-x-6">
                <Link href="/" className="text-gray-600 hover:text-gray-900">Home</Link>
                <Link href="/services" className="text-gray-600 hover:text-gray-900">Services</Link>
                <Link href="/portfolio" className="text-gray-600 hover:text-gray-900">Portfolio</Link>
                <Link href="/about" className="text-gray-600 hover:text-gray-900">About</Link>
                <Link href="/contact" className="text-gray-600 hover:text-gray-900">Contact</Link>
              </nav>
            )}
  
            {isMobile && (
              <button
                className="text-gray-600 focus:outline-none"
                onClick={toggleMenu}
                aria-label="Toggle menu"
              >
                <Menu size={24} />
              </button>
            )}*/}
          </div>
  
          {/* {isMobile && isMenuOpen && (
            <nav className="mt-4 space-y-2">
              <Link href="/" className="block py-2 text-gray-600 hover:text-gray-900">Home</Link>
              <Link href="/services" className="block py-2 text-gray-600 hover:text-gray-900">Services</Link>
              <Link href="/portfolio" className="block py-2 text-gray-600 hover:text-gray-900">Portfolio</Link>
              <Link href="/about" className="block py-2 text-gray-600 hover:text-gray-900">About</Link>
              <Link href="/contact" className="block py-2 text-gray-600 hover:text-gray-900">Contact</Link>
            </nav>
          )}  */}
        </div>
      </header>
    );
  };

export default Header;
