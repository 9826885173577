import styles from './Header.module.css';
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { FaLinkedin, FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Globe } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          <div className="mb-4">
            <Image 
              src="/logo.svg" 
              alt="Sloane Logo" 
              width={120} 
              height={40} 
              className="h-10 w-auto"
            />
          </div>
          <div className="text-center text-sm">
            <p>&copy; {new Date().getFullYear()} Sloane Engineering Agency. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};





export const Footer2: React.FC = () => {
  return (
    <footer className="bg-secondary-dark text-white py-12">
      <div className="container max-w-8xl mx-auto px-4">
        

        {/* <hr className="border-gray-700 my-8" /> */}
      
      <div className="flex items-left space-x-6 -mt-3">
        {/* <Globe size={24} className="text-gray-500" /> */}
        <div>
        <Link href="/">
          <img className="h-7" src="/images/logo-white.svg" alt="Sloane InfoTech"  />
        {/* <Image className={`${styles.image}`} src="/images/logo-white.svg" alt="Sloane InfoTech" width={150} height={64} /> */}
        </Link>
        
        <p className="text-sm pl-1">999 Corporate Drive, Suite 100<br />Ladera Ranch, CA 92694</p>
        </div>
        <div className="flex-1"></div>
        <nav>
          <ul className="flex space-x-6">
            {/* <li>
              <Link href="/impressum" className="text-gray-500 hover:text-gray-700">
                Impressum
              </Link>
            </li> */}
            <li>
              <Link href="/privacy-policy" className="text-gray-500 hover:text-gray-700">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link href="/cookie-policy" className="text-gray-500 hover:text-gray-700">
                Cookie Policy
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      </div>
     
    

      <div className="flex items-center">
       
      </div>
    </footer>
  );
};
export default Footer;