import emailjs from '@emailjs/browser';
import { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_PUBLIC_KEY } from '../emailjs.config';

interface EmailParams {
  to_name: string;
  from_name: string;
  message: string;
}

export const sendEmail = async (params: Record<string, unknown>): Promise<void> => {
  try {
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      params,
      {publicKey:EMAILJS_PUBLIC_KEY}
    );
    console.log('Email sent successfully:', response);
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};