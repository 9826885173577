export const Svg1 = () => {
    return (
        <div className="absolute inset-0 w-full h-full" style={{ zIndex: 0 }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 800" preserveAspectRatio="none" className="w-full h-full">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#1a2133', stopOpacity: 1 }} />
                <stop offset="25%" style={{ stopColor: '#23304a', stopOpacity: 1 }} />
                <stop offset="50%" style={{ stopColor: '#2c3e61', stopOpacity: 1 }} />
                <stop offset="75%" style={{ stopColor: '#354d78', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#3e5c8f', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path fill="url(#grad1)" d="M0,0 L1440,0 L1440,800 L0,800 Z" />
            <path fill="#1e1c1e" fillOpacity="0.1" d="M0,800 C360,680 720,760 1440,640 L1440,800 L0,800 Z" />
            <path fill="#eaa368" fillOpacity="0.05" d="M0,800 C480,750 960,790 1440,720 L1440,800 L0,800 Z" />
          </svg>
        </div>
    );
  };
export const Svg2 = () => {
    return (
        <div className="absolute inset-0 w-full h-full" style={{ zIndex: 0 }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 800" preserveAspectRatio="none" className="w-full h-full">
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#4338ca', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#3b82f6', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <path fill="url(#grad1)" d="M0,0 L1440,0 L1440,800 L0,800 Z" />
            <path fill="rgba(255,255,255,0.1)" d="M0,800 C360,680 720,760 1440,640 L1440,800 L0,800 Z" />
            <path fill="rgba(255,255,255,0.05)" d="M0,800 C480,750 960,790 1440,720 L1440,800 L0,800 Z" />
            </svg>
        </div> 
    );
};

export const Svg3 = () => {
  return (
    <div className="absolute inset-0 w-full h-full" style={{ zIndex: 0 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 800"
        preserveAspectRatio="xMidYMid slice"
        className="w-full h-full"
      >
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#1a2133', stopOpacity: 1 }} />
            <stop offset="25%" style={{ stopColor: '#23304a', stopOpacity: 1 }} />
            <stop offset="50%" style={{ stopColor: '#2c3e61', stopOpacity: 1 }} />
            <stop offset="75%" style={{ stopColor: '#354d78', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#3e5c8f', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <path fill="url(#grad1)" d="M0,0 L1440,0 L1440,800 L0,800 Z" />
        <path fill="#1e1c1e" fillOpacity="0.1" d="M0,800 C360,680 720,760 1440,640 L1440,800 L0,800 Z" />
        <path fill="#eaa368" fillOpacity="0.05" d="M0,800 C480,750 960,790 1440,720 L1440,800 L0,800 Z" />
      </svg>
    </div>
  );
};