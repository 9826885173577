import React from 'react';
import styles from './ProcessSection.module.css';
import { 
  Search, 
  Paintbrush, 
  TestTube, 
  Upload, 
  Settings,
  Code,
  Zap,
  Smartphone,
  ChevronDown,
  LucideIcon
} from 'lucide-react';
import Head from 'next/head';
import Image from 'next/image';
import { handleClick } from '@/Utils';



type ProcessStep = {
  number: number;
  title: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  description: string;
};

const processSteps: ProcessStep[] = [
  {
    number: 1,
    title: 'Define',
    icon: Search,
    description: 'Outline project goals and create a comprehensive development plan.',
  },
  {
    number: 2,
    title: 'Design',
    icon: Paintbrush,
    description: 'Craft intuitive interfaces and robust system architecture.',
  },
  {
    number: 3,
    title: 'Develop',
    icon: Code,
    description: 'Build the application with cutting-edge technologies and best practices.',
  },
  {
    number: 4,
    title: 'Test',
    icon: TestTube,
    description: 'Perform thorough testing to ensure quality and reliability.',
  },
  {
    number: 5,
    title: 'Deploy',
    icon: Upload,
    description: 'Launch the solution securely in the intended environment.',
  },
  {
    number: 6,
    title: 'Maintain',
    icon: Settings,
    description: 'Provide ongoing support and implement continuous improvements.',
  },
];

interface ProcessStepProps extends ProcessStep {
  isLast: boolean;
}

const ProcessStep: React.FC<ProcessStepProps> = ({ number, title, icon: Icon, description, isLast }) => (
  <div className={`${styles.processStep} ${!isLast ? styles.connector : ''} relative p-6 bg-white rounded-lg shadow-md`}>
    <div  className="bg-neutral-500 absolute -top-4 -left-4 flex items-center justify-center w-8 h-8 text-white rounded-full z-10">
      <span className="text-l font-bold">{number}</span>
    </div>
    <div className="flex items-start mb-0 mt-0">
      <Icon className="text-primary w-8 h-8 mr-3 flex-shrink-0" />
      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

export const SoftwareDevelopmentProcess: React.FC = () => {
  return (
    <section className="py-12 sm:py-16  bg-gray-100">
      <div className="container max-w-6xl mx-auto px-4 sm:px-10">
        <h2 className="mb-4 text-3xl font-bold text-center text-gray-800">Our Process</h2>
        <p className="mb-8 text-center text-gray-600 max-w-2xl mx-auto px-4">
        We ensure high-quality solutions through a <strong>software development process</strong> that includes detailed planning, agile methodologies, and rigorous testing. Our commitment to collaboration, transparency, and continuous improvement helps us meet and exceed client expectations.
        </p>
        <div className={`${styles.processSteps} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8`}>
          {processSteps.map((step, index) => (
            <ProcessStep key={step.number} {...step} isLast={index === processSteps.length - 1} />
          ))}
        </div>
        <div className="mt-12 text-center">
          <a
            href="#contact"
            onClick={handleClick}
            className="bg-primary text-white  hover:bg-primary-dark font-semibold inline-flex items-center px-6 py-3  rounded-full  transition duration-300 hover:scale-105"
          >
            Start the Conversation
            <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};





const ProcessSection= () => {
  return  (
  <section id="process" className="container mx-auto py-20 px-4">
    <h3 className="text-3xl font-bold mb-10 text-center">Our Process</h3>
    <div className="flex flex-col items-center">
      <div className="flex items-center mb-6">
        <div className="bg-blue-600 rounded-full p-3 mr-4">
          <Zap className="w-6 h-6" />
        </div>
        <p className="text-xl">Discovery & Strategy</p>
      </div>
      <ChevronDown className="w-6 h-6 mb-6" />
      <div className="flex items-center mb-6">
        <div className="bg-blue-600 rounded-full p-3 mr-4">
          <Code className="w-6 h-6" />
        </div>
        <p className="text-xl">Development & Testing</p>
      </div>
      <ChevronDown className="w-6 h-6 mb-6" />
      <div className="flex items-center">
        <div className="bg-blue-600 rounded-full p-3 mr-4">
          <Zap className="w-6 h-6" />
        </div>
        <p className="text-xl">Deployment & Support</p>
      </div>
    </div>
    </section>
  );
}

export default ProcessSection 


