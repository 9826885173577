export function smoothScroll(targetId: string): void {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
export interface SmoothScrollLinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}
// export function SmoothScrollLink({ href, children, className }: SmoothScrollLinkProps) {
export const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  const target = e.currentTarget;
  if (target.hash && target.pathname === window.location.pathname) {
    e.preventDefault();
    const targetId = target.hash.slice(1);
    smoothScroll(targetId);
  }
}
      