import React from 'react';
import Link from 'next/link'
import { Users, Award, Rocket } from 'lucide-react'

export default function About() {
  return (
    <section className="bg-background-light text-text mx-auto py-20 px-4">
       <div className="container max-w-6xl mx-auto px-4 sm:px-10">
        <h2 className="text-4xl font-bold mb-10 text-center">About Sloane</h2>
        
        <div className="max-w-6xl mx-auto">
          <p className="text-xl mb-8">
            Sloane is a cutting-edge technology agency founded by industry veterans with a passion for innovation. 
            We specialize in transforming complex challenges into elegant, scalable solutions.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="flex flex-col items-center text-center">
              <Users className="w-12 h-12 mb-4 text-blue-400" />
              <h3 className="text-xl font-bold mb-2">Expert Team</h3>
              <p>Led by seasoned professionals with decades of combined experience</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <Award className="w-12 h-12 mb-4 text-blue-400" />
              <h3 className="text-xl font-bold mb-2">Proven Track Record</h3>
              <p>Successfully delivered projects for Fortune 500 companies and startups alike</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <Rocket className="w-12 h-12 mb-4 text-blue-400" />
              <h3 className="text-xl font-bold mb-2">Innovation-Driven</h3>
              <p>Constantly exploring new technologies to provide cutting-edge solutions</p>
            </div>
          </div>

          <p className="text-xl mb-8">
            Our mission is to empower businesses with technology that drives growth, efficiency, and competitive advantage. 
            From cloud-native applications to AI-powered analytics, we&apos;re committed to delivering solutions that make a real impact.
          </p>
        </div>
      </div>
  </section>
  )
}