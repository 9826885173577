'use client';


import React from 'react';
import { useState } from 'react';
import { ChevronDown, Code, Cloud, Brain, Smartphone, Zap, Mail, Users, Award, Rocket } from 'lucide-react';
import ProcessSection, { SoftwareDevelopmentProcess } from '@/components/ProcessSection';
import ContactUsSection from '@/components/ContactUsSection';
import Footer, { Footer2 } from '@/components/Footer';
import ProjectsSection from '@/components/ProjectsSection';
import ExpertiseSection, { ExpertiseSection2 } from '@/components/ExpertiseSection';

import Header, { Header2, Header3 } from '@/components/Header';
import About from '@/components/About';
import ServicesSection, { Services2Section } from '@/components/ServicesSection';
import { FullstackHero, Hero3, Hero_82801_Section,  HeroStaffAug1Section, InnovativeSolutionsHeroSection } from '@/components/hero/Hero';
import CTA, { CTA2 } from '@/components/hero/CallToAction';
import CustomSoftwareServices from '@/components/ServicesSection2';

export default function SloaneAgencyPage() {
  const [activeTab, setActiveTab] = useState('expertise');

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white font-sans">
        {/* 
        Proposed sections
        <Header />
        <Hero />
        <Services />
        <Process />
        <Testimonials />
        <CTA /> 
        <Footer />
        
        */}

      
      
      {/* Header */}
      <Header3 />




      



      {/* Hero Section */}
      <Hero_82801_Section />
      {/* <InnovativeSolutionsHeroSection /> */}
      {/* <HeroStaffAug1Section /> */}
      

      {/* Services Section */}
      <CustomSoftwareServices />
      {/* <Services2Section /> */}


      {/* Process Section */}
      <SoftwareDevelopmentProcess />
      {/* <ProcessSection /> */}

      {/* Expertise Section */}
      {/* <ExpertiseSection2 /> */}
      


      
      <About />

      {/* Projects Section */}
      {/* <ProjectsSection /> */}

      {/* <CTA /> */}
  

      {/* Contact Section */}
      <ContactUsSection />

      {/* Footer */}
      <Footer2 />
      {/* <footer className="bg-gray-900 py-6 text-center">
        <p>&copy; 2024 Sloane. All rights reserved.</p>
      </footer> */}
    </div>
  );
}