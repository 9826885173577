import React from 'react';
import { Star } from 'lucide-react';
import Image from "next/image";
import Link from "next/link";
import { Svg1, Svg2, Svg3 } from '../backgrounds/Svg';
import { smoothScroll } from '@/Utils';


export const Hero = () => (
    <div className="bg-gradient-to-r from-blue-600 to-indigo-800 text-white py-20">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl">
          <h1 className="text-5xl font-bold mb-6">Elevate Your Digital Presence with Fullstack Excellence</h1>
          <p className="text-xl mb-8">At Sloane, we craft cutting-edge solutions that seamlessly integrate front-end finesse with back-end robustness.</p>
          <Link href="#contact" className="bg-white text-blue-600 font-bold py-3 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );

export const Hero2 = () => (
<div className="bg-gradient-to-r from-blue-600 to-indigo-800 text-white py-20">
    <div className="container mx-auto px-4">
    <div className="max-w-3xl">
        <h1 className="text-5xl font-bold mb-6">Elevate Your Digital Presence with Fullstack Excellence</h1>
        <p className="text-xl mb-8">At Sloane, we craft cutting-edge solutions that seamlessly integrate front-end finesse with back-end robustness.</p>
        <Link href="#contact" className="bg-white text-blue-600 font-bold py-3 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        Get Started
        </Link>
    </div>
    </div>
</div>
);


export const FullstackHero = () => {
  return (
    <div className="bg-gradient-to-r from-blue-600 to-indigo-800 text-white">
      <div className="container mx-auto px-4 py-16 sm:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <h1 className="text-4xl sm:text-5xl font-bold mb-4">
              Elevate Your Digital Presence with Fullstack Excellence
            </h1>
            <p className="text-xl mb-8">
              At Sloane, we craft cutting-edge solutions that seamlessly integrate front-end finesse with back-end robustness.
            </p>
            <ul className="text-lg mb-8">
              <li className="flex items-center mb-2">
                <svg className="w-6 h-6 mr-2 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                Scalable Cloud-Native Applications
              </li>
              <li className="flex items-center mb-2">
                <svg className="w-6 h-6 mr-2 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                AI-Powered Solutions
              </li>
              <li className="flex items-center">
                <svg className="w-6 h-6 mr-2 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                High-Performance Microservices
              </li>
            </ul>
            <button className="bg-white text-blue-600 font-bold py-3 px-6 rounded-full hover:bg-blue-100 transition duration-300">
              Discover Our Expertise
            </button>
          </div>
          <div className="hidden md:block">
            <Image
              src="/fullstack-illustration.svg"
              alt="Fullstack Development Illustration"
              width={600}
              height={400}
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};





export const InnovativeSolutionsHeroSection = () => {
  return (
  <section className="container mx-auto py-20 px-4 text-center">
<h2 className="text-5xl font-bold mb-6">Innovative Solutions for the Digital Age</h2>
<p className="text-xl mb-10">Transforming ideas into powerful, scalable technologies</p>
<a href="#contact" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition">Get in Touch</a>
</section>
  );
}


export const Hero3 = () => (
  <section className="bg-blue-700 text-white py-20">
  <div className="container mx-auto px-4 text-center">
    <h2 className="text-4xl font-bold mb-4">Transforming Ideas into Powerful Software</h2>
    <p className="text-xl mb-8">Expertise in cloud-native applications and cutting-edge technologies</p>
    <button className="bg-white text-blue-700 font-bold py-2 px-4 rounded hover:bg-blue-100 transition duration-300">
      Get a Consultation
    </button>
  </div>
</section>
);


export const HeroStaffAug1Section = () => {
  return (
    <section className="relative bg-blue-600 text-white py-12 px-4 md:px-8 overflow-hidden">
      {/* Background SVG */}
      <Svg1 />
   
  

      {/* Content */}
      <div className="max-w-6xl mx-auto relative z-10 text-center text-white">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Elevate Your Tech Projects with Elite Remote Developers
        </h1>
        <p className="text-lg mb-8 mx-auto max-w-3xl text-white/80">
          Experience the perfect blend of in-house dedication and freelance flexibility. Seamlessly integrate top-tier talent into your existing team or build a new one from scratch, without the complexities of traditional hiring.
        </p>

        <div className="flex flex-wrap justify-center gap-4 mb-8">
          <button className="bg-[#1a2133] text-white px-6 py-2 rounded-full font-semibold hover:bg-[#23304a] transition-colors">
            EXPLORE TALENT POOL
          </button>
          
        </div>

        <div className="flex items-center justify-center gap-2 mb-8 text-white/80">
          <div className="flex">
            {[...Array(5)].map((_, i) => (
              <Star key={i} className="w-5 h-5 fill-current text-[#eaa368]" />
            ))}
          </div>
          <span>Trustpilot</span>
          <span className="font-semibold">TrustScore 4.8</span>
        </div>

        <div className="grid md:grid-cols-3 gap-6 mb-12">
          {/* Placeholder for developer cards */}
          {[...Array(3)].map((_, i) => (
            <div key={i} className="bg-[#2c3e61]/50 backdrop-blur-sm rounded-lg p-4 shadow-lg">
              <div className="w-32 h-32 bg-[#1a2133] rounded-full mb-4 mx-auto" />
              <h3 className="font-semibold text-lg">Developer Name</h3>
              <p className="text-white/80">Role</p>
              <div className="flex flex-wrap justify-center gap-2 mt-2">
                <span className="bg-[#eaa368]/20 px-2 py-1 rounded text-sm text-white">Skill</span>
                <span className="bg-[#eaa368]/20 px-2 py-1 rounded text-sm text-white">Skill</span>
              </div>
            </div>
          ))}
        </div>

        <div className="grid md:grid-cols-4 gap-6 text-sm text-white/80">
          {[
            '4,000+ vetted developers, only 1% of applicants accepted',
            '500+ tech competencies',
            '2 days average to present great-fit candidates',
            'Expert technical advice and talent coaching'
          ].map((text, i) => (
            <div key={i} className="flex items-start gap-2 justify-center">
              <svg className="w-5 h-5 text-[#eaa368] flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span>{text}</span>
            </div>
          ))}
        </div>

        <div className="flex flex-wrap items-center justify-center gap-8 mt-12">
          <span className="font-semibold">TRUSTED BY</span>
          {/* Placeholder for company logos */}
          {[...Array(6)].map((_, i) => (
            <div key={i} className="w-24 h-8 bg-white/10 rounded" />
          ))}
        </div>
      </div>
    </section>
  );
};

export const Hero_82801_Section = () => {
  return (
    <section className="relative text-white overflow-hidden ">
     <Svg1 />
  
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 lg:py-32 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tight mb-6 text-white drop-shadow-lg">
              Stay Ahead in the AI Revolution
            </h1>
            <p className="text-xl sm:text-2xl mb-10 max-w-2xl mx-auto lg:mx-0 text-gray-100">
              Your competitors are accelerating. We&apos;ll help you lead the pack.
            </p>
            <button onClick={()=>smoothScroll('contact')} className="bg-primary text-white font-bold py-4 px-8 rounded-full transition duration-300 transform hover:scale-105 hover:bg-primary-dark shadow-lg">
              Accelerate Your Innovation
            </button>
           
          </div>
          
        </div>
      </div>
    </section>
  );
};
export const Hero_82801_Section_img = () => {
  return (
    <section className="relative text-white overflow-hidden min-h-screen">
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 lg:py-32 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tight mb-6 text-white drop-shadow-lg">
              Stay Ahead in the AI Revolution
            </h1>
            <p className="text-xl sm:text-2xl mb-10 max-w-2xl mx-auto lg:mx-0 text-gray-100">
              Your competitors are accelerating. We&apos;ll help you lead the pack.
            </p>
            <button className="bg-white text-purple-800 font-bold py-4 px-8 rounded-full transition duration-300 transform hover:scale-105 hover:bg-gray-200 shadow-lg">
              Accelerate Your Innovation
            </button>
          </div>
          <div className="relative h-80 sm:h-96 lg:h-[500px] rounded-2xl overflow-hidden shadow-2xl transform hover:scale-105 transition duration-300">
            <Image
              src="/images/accellerate-ai.jpeg"
              alt="AI Revolution Visualization"
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 1024px) 100vw, 50vw"
              className="rounded-2xl"
              priority
            />
            <div className="absolute inset-0 bg-gradient-to-t from-purple-900/50 to-transparent"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
