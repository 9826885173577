'use client';

import React, { useRef, useState, FormEvent } from 'react';
import Link from 'next/link';
import { sendEmail } from '@/api/mail';





export const ContactUsSection = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');


    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setError('');
        try {
            await sendEmail({
                to_name: 'Sales',
                from_name: name,
                message: `From: ${email}\n\n${message}`,
            });

            // alert('Email sent successfully!');
            // throw 'Error sending email';
            setIsSubmitted(true);


            // Reset form fields
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            setError('Failed to send email. Please try again.');
        }
    };

    if (isSubmitted) {
        return (
            <section id="contact" className="container mx-auto py-20 px-4">
                <div className="max-w-lg mx-auto text-center">
                    <h3 className="text-3xl font-bold mb-4">Thank You!</h3>
                    <p className="mb-8">Your message has been sent successfully. We&apos;ll get back to you soon.</p>
                    <button
                        onClick={() => setIsSubmitted(false)}
                        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition"
                    >
                        Send Another Message
                    </button>
                </div>
            </section>
        );
    }
    return (
        <section id="contact" className="bg-secondary-light  text-text-light mx-auto py-20 px-4">
            <div className="container max-w-6xl mx-auto px-4 sm:px-10">
                <h3 className="text-3xl font-bold mb-10 text-center">Accelerate Your Business with AI-Powered Engineering</h3>
                <p className="text-xl mb-8">You&apos;re one step away from transforming your engineering processes with cutting-edge AI. Whether you&apos;re looking to optimize your workflow, integrate AI into your existing systems, or explore new possibilities, we&apos;re here to guide you. Fill out the form below, and let&apos;s start accelerating your business together.</p>
                {error && (
                    <div className="max-w-lg mx-auto mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                        <p>{error}</p>
                    </div>
                )}
                <form
                    onSubmit={handleSubmit}
                    className="max-w-lg mx-auto">
                    <input type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Name" className="w-full mb-4 p-2 rounded " />
                    <input type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Email" className="w-full mb-4 p-2 rounded " />
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        placeholder="Message" rows={4} className="w-full mb-4 p-2 rounded "></textarea>
                    <button type="submit" className="block mx-auto bg-primary text-white font-bold py-4 px-8 rounded-full transition duration-300 transform hover:scale-105 hover:bg-primary-dark shadow-lg">
                            Book a Free Consultation
                    </button>
                    

                        
                </form>
            </div>
        </section>

    );
}




export const ContactUsSection2 = () => {
    return (
        <section id="contact" className="py-20 bg-gray-100">
            <div className="container mx-auto px-4 text-center">
                <h2 className="text-3xl font-bold mb-8">Ready to Elevate Your Digital Presence?</h2>
                <p className="text-xl mb-8">Let&quot;s discuss how Sloane can bring your vision to life with our fullstack expertise.</p>
                <Link href="/contact" className="bg-blue-600 text-white font-bold py-3 px-6 rounded-full hover:bg-blue-700 transition duration-300">
                    Contact Us
                </Link>
            </div>
        </section>
    );

}
export const ContactUsSection3 = () => {
    return (
        <section className="py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">Get In Touch</h2>
                <form className="max-w-lg mx-auto">
                    <input type="text" placeholder="Name" className="w-full mb-4 p-2 border rounded" />
                    <input type="email" placeholder="Email" className="w-full mb-4 p-2 border rounded" />
                    <textarea placeholder="Message" className="w-full mb-4 p-2 border rounded h-32"></textarea>
                    <button type="submit" className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                        Send Message
                    </button>
                </form>
            </div>
        </section>

    );
}



export default ContactUsSection