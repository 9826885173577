import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { 
  Globe, 
  Smartphone, 
  HeadphonesIcon, 
  Building2, 
  Package, 
  PuzzleIcon, 
  Waypoints, 
  Cloud, 
  CloudCog, 
  RefreshCcw, 
  Icon
} from 'lucide-react';
import { handleClick } from '@/Utils';


interface ServiceCard {
  icon: React.ElementType;
  title: string;
  description: string;
  link?: string;
}



const serviceCards: ServiceCard[] = 
[
  {
    icon: Globe,
    title: 'Web Development',
    description: 'Elevate your online presence with our tailored web applications. We craft high-performance, user-centric solutions that not only look stunning but also drive engagement and streamline operations. Let us transform your vision into a powerful digital asset that sets you apart from the competition.',
    link: '/web-application-development'
  },
  {
    icon: Smartphone,
    title: 'Mobile App Development',
    description: 'Unlock new opportunities with our cutting-edge mobile apps. Whether it\'s iOS, Android, or cross-platform, we deliver robust, scalable solutions that captivate users and fuel business growth. Embrace the mobile-first world and watch your customer base expand exponentially.',
    link: '/mobile-app'
  },
  {
    icon: HeadphonesIcon,
    title: 'Software Consulting Services',
    description: 'Navigate the complex tech landscape with our expert guidance. From tech stack optimization to team augmentation, we provide strategic insights that align technology with your business goals. Make informed decisions and maximize your ROI with our comprehensive software consulting services.'
  },
  {
    icon: Building2,
    title: 'Enterprise Software Development',
    description: 'Revolutionize your enterprise operations with our bespoke software solutions. We create powerful tools that optimize workflows, enhance communication, and boost productivity. Experience seamless integration and ongoing support that empowers your business to thrive in an ever-evolving marketplace.'
  },
  {
    icon: Package,
    title: 'Software Product Development',
    description: 'Turn your innovative ideas into market-ready software products. Our agile development approach and focus on cutting-edge technology ensure your product stands out in a crowded market. From startups to established businesses, we deliver solutions that drive sustainable success and keep you ahead of the curve.'
  },
  {
    icon: PuzzleIcon,
    title: 'Software Integration Services',
    description: 'Unify your digital ecosystem with our seamless integration services. We break down silos, automate workflows, and enable efficient data exchange across your entire IT landscape. Experience the power of a fully connected, harmonious software environment that drives productivity to new heights.'
  },
  {
    icon: Waypoints,
    title: 'API Development',
    description: 'Unleash the full potential of your applications with our robust API solutions. We create secure, scalable APIs that enable seamless data exchange and unlock new functionalities. Enhance your app\'s capabilities, foster innovation, and open doors to exciting partnerships and integrations.',
    link: '/api-development'
  },
  {
    icon: Cloud,
    title: 'SaaS Development',
    description: 'Dominate the cloud market with our state-of-the-art SaaS solutions. Whether building from scratch or modernizing existing software, we deliver intuitive, scalable, and integration-ready products. Embrace the future of software delivery and provide unparalleled value to your customers.'
  },
  {
    icon: CloudCog,
    title: 'Cloud Development',
    description: 'Harness the limitless potential of the cloud with our expert development services. We build resilient, scalable, and cost-effective cloud solutions that propel your business forward. Reduce IT overhead, enhance collaboration, and stay agile in a rapidly changing digital landscape.'
  },
  {
    icon: RefreshCcw,
    title: 'Software Modernization',
    description: 'Breathe new life into your legacy systems with our modernization expertise. We transform outdated software into cutting-edge, efficient solutions that reduce costs and amplify performance. Embrace cloud technologies, microservices architecture, and enhanced UI/UX to future-proof your business.',
    link: '/legacy-software-modernization'
  }
];


// [
//   {
//     icon: Globe,
//     title: 'Web Development',
//     description: 'Gain a competitive edge with our comprehensive web application development services. We create bespoke, high-performance web applications after thoroughly understanding your business requirements. Our expertise ensures your site looks great, enhances user engagement, and streamlines workflows, delivering maximum value from your online presence.',
//     link: '/web-application-development'
//   },
//   {
//     icon: Smartphone,
//     title: 'Mobile App Development',
//     description: 'Empower your business with our mobile app development services. We create robust and scalable iOS, Android, and cross-platform apps that streamline workflows, enhance user engagement, and maximize your online presence. Using the latest technologies, we ensure your app delivers tangible business benefits, driving growth and competitiveness in today\'s market.',
//     link: '/mobile-app'
//   },
//   {
//     icon: HeadphonesIcon,
//     title: 'Software Consulting Services',
//     description: 'Our software consulting spans vital areas, including tech stack assessment, software selection, team augmentation, and more. We gather and analyze your business requirements, enabling us to conceptualize software that effectively addresses them. This ensures seamless alignment with your business objectives and maximizes the value derived from custom software development.'
//   },
//   {
//     icon: Building2,
//     title: 'Enterprise Software Development',
//     description: 'Simplify your enterprise operations and maximize efficiency and productivity with our bespoke software development services. Whether it\'s optimizing workflows, improving communication, or enhancing customer engagement, we tailor solutions to meet your specific business needs. Also, we ensure seamless integration and ongoing support, empowering your enterprise to thrive in today\'s dynamic marketplace.'
//   },
//   {
//     icon: Package,
//     title: 'Software Product Development',
//     description: 'Transform your ideas into cutting-edge software products with us. From concept to creation, we ensure your software stands out, addresses unique challenges, and drives sustainable success. With agile development and a focus on innovation, we empower startups and established businesses alike. As a leading software development company, we deliver solutions that move businesses forward.'
//   },
//   {
//     icon: PuzzleIcon,
//     title: 'Software Integration Services',
//     description: 'We specialize in integrating diverse software systems seamlessly, using advanced middleware solutions for smooth, unified operations and better productivity. Our strategic integration ensures efficient data exchange and automation of business workflows, improving operational efficiency and breaking down barriers within your IT ecosystem.'
//   },
//   {
//     icon: Waypoints,
//     title: 'API Development',
//     description: 'Streamline app interactions with our API development services. We design secure, powerful APIs, including RESTful, SOAP, and GraphQL, that enable seamless integrations and data exchanges between your applications and third-party services. This strategic connectivity enhances functionality and user experience, leading to more efficient operations and encouraging innovation and collaboration.',
//     link: '/api-development'
//   },
//   {
//     icon: Cloud,
//     title: 'SaaS Development',
//     description: 'Move your business to the cloud with our SaaS development services. We can build brand-new and easy-to-use SaaS products from scratch or re-architect existing software using the latest technologies. Our focus on intuitive UI/UX design and API-driven development ensures that your SaaS solution delivers a seamless user experience and integrates well with existing business systems.'
//   },
//   {
//     icon: CloudCog,
//     title: 'Cloud Development',
//     description: 'Ensure a global presence with our cloud development services. We deliver tailored, resilient, and efficient cloud solutions that fulfill your business needs, promoting rapid scalability and operational flexibility. Using the cloud, we help you reduce IT overhead, improve collaboration, and drive innovation, ensuring your business stays ahead of the curve.'
//   },
//   {
//     icon: RefreshCcw,
//     title: 'Software Modernization',
//     description: 'Reduce maintenance costs and enhance features, performance, security, and scalability with our software modernization services. We assist in migrating legacy systems and use advanced technologies for software re-engineering. Our experts specialize in cloud migration, UI/UX enhancements, and transforming monolithic architectures into agile microservices, ensuring modern, efficient systems.',
//     link: '/legacy-software-modernization'
//   }
// ];

const ServiceCard: React.FC<ServiceCard & { isOpen: boolean; toggleOpen: () => void }> = ({ icon: Icon, title, description, link, isOpen, toggleOpen }) => (
  <div className="mb-4">
    <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-0.5 rounded-lg">
      <div className="bg-white rounded-lg p-4">
        <div className="flex items-center justify-between cursor-pointer" onClick={toggleOpen}>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Icon className="w-6 h-6 text-blue-500" />
            </div>
            <h3 className="text-lg font-semibold text-gray-800 ml-4 flex items-center leading-tight service-card">
              <span className="inline-block align-middle">{title}</span>
            </h3>
          </div>
          <span className="text-2xl text-gray-800">{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="mt-4">
            <p className="text-gray-700">{description}</p>
            {/* {link && (
              <Link href={link} className="text-blue-500 hover:underline mt-2 inline-block">
                Learn more
              </Link>
            )} */}
          </div>
        )}
      </div>
    </div>
  </div>
);

const CustomSoftwareServices: React.FC = () => {
  const [openCards, setOpenCards] = useState<{ [key: string]: boolean }>({});

  const toggleCard = (title: string) => {
    setOpenCards(prev => ({ ...prev, [title]: !prev[title] }));
  };

  return (
    <section className="py-16 bg-gray-50">
      <div className="container max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-4 text-gray-800">
          Full Range of Custom Software <span className="block">Development Services</span>
        </h2>
        <p className="text-center text-gray-700 mb-12 max-w-3xl mx-auto">
          We provide a complete range of <strong>custom software development services</strong> to meet your specific business needs. From initial concept to final deployment, we provide tailored solutions that ensure optimal performance, scalability, and user satisfaction.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {serviceCards.map((card, index) => (
            <ServiceCard
              key={index}
              {...card}
              isOpen={openCards[card.title] || false}
              toggleOpen={() => toggleCard(card.title)}
            />
          ))}
        </div>
        <div className="text-center mt-12">
          <Link 
            href="#contact" 
            onClick={handleClick}
            className="bg-primary hover:bg-primary-dark inline-flex items-center px-6 py-3 text-white font-semibold rounded-full transition duration-300 transform hover:scale-105"
          >
            Start Building Now
            {/* <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg> */}
          </Link>
        </div>
      </div>
    </section>
  );
};
export default CustomSoftwareServices;